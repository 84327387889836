import React from "react";
import Layout from "../components/Layout";

import "./soundcloud.css";
import { useMediaQuery } from "@material-ui/core";
import { useTheme } from "@material-ui/core/styles";
import { Helmet } from "react-helmet";
import comp3 from "../images/compressed/comp3.png";

function MetaTags() {
  return (
    <Helmet>
      <title>{`Our Music - Pantano Music`}</title>
      <meta name="description" content="Listen to Pantano music" />
      <meta property="og:title" content="Our Music - Pantano Music" />
      <meta property="og:description" content="Listen to Pantano music" />
      <meta property="og:image" content={comp3} />
      <meta property="og:url" content="https://www.pantanomusic.com/music" />
      <meta name="twitter:card" content="summary_large_image" />
    </Helmet>
  );
}

export default function MusicPage() {
  const theme = useTheme();
  const breakPoint = useMediaQuery(theme.breakpoints.up("md"));
  return (
    <Layout>
      <MetaTags />
      <h1>Listen to our music</h1>

      <iframe
        width={breakPoint ? "50%" : "100%"}
        height="450"
        scrolling="no"
        frameborder="no"
        allow="autoplay"
        title="Pantano Music soundcloud playlist"
        src="https://w.soundcloud.com/player/?url=https%3A//api.soundcloud.com/playlists/1261715335&color=%23ff5500&auto_play=false&hide_related=false&show_comments=true&show_user=true&show_reposts=false&show_teaser=true"
      ></iframe>
      <div className="soundcloud">
        <a
          href="https://soundcloud.com/musicpantano"
          title="Pantano Music"
          target="_blank"
          rel="noreferrer"
          className="soundcloud-link"
        >
          Pantano Music
        </a>

        {/* <iframe
        width="50%"
        height="400"
        scrolling="no"
        frameborder="no"
        allow="autoplay"
        src="https://w.soundcloud.com/player/?url=https%3A//api.soundcloud.com/playlists/1261715335&color=%23ff5500&auto_play=false&hide_related=false&show_comments=true&show_user=true&show_reposts=false&show_teaser=true&visual=true"
      ></iframe>
      <div className="soundcloud">
        <a
          href="https://soundcloud.com/musicpantano"
          title="Pantano Music"
          target="_blank"
          className="soundcloud-link"
        >
          Pantano Music
        </a>
      </div> */}
      </div>
    </Layout>
  );
}
